 <template  >
  <div class="mask" v-if="showEditor">
    <div class="push-info">
      <a-row gutter="10" style="height: 100%">
        <a-col :span="18" class="phone-pre"
          ><div class="iframe-p">
            <iframe
              src=" "
              style="height: 72vh;overflow: auto;margin:15px 0;width:100%"
              frameborder="0"
            ></iframe></div
        ></a-col>
        <a-col
          :span="6"
          style="
            background: #fff;
            height: 100%;
            overflow-y: auto;
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
          "
          ><a-tabs default-active-key="0">
            <a-tab-pane key="0" tab="传播推送" style="padding: 20px">
              <h4>微信分享设置</h4>
              <a-row
                type="flex"
                justify="space-around"
                style="border: 1px solid #e8e8e8; padding: 10px"
              >
                <a-col :span="17">
                  <a-input
                    placeholder="微信分享标题"
                    style="border: none; outline: none"
                  ></a-input>
                  <div class="line"></div>
                  <a-textarea
                    style="border: none; outline: none"
                    placeholder="分享描述"
                    allow-clear
                    @change="onChange"
                /></a-col>
                <a-col style="margin: auto" :span="5">
                  <a-upload
                    name="avatar"
                    list-type="picture-card"
                    :show-upload-list="false"
                    action=" "
                    :before-upload="beforeUpload"
                  >
                    <img v-if="imageUrl" :src="imageUrl" alt="avatar" />
                    <div v-else>
                      <a-icon :type="loading ? 'loading' : 'plus'" />
                      <div class="ant-upload-text">封面图</div>
                    </div>
                  </a-upload>
                </a-col>
              </a-row>

              <h4>内容</h4>
              <richeditor></richeditor>
              <a-row
                type="flex"
                style="text-align: center; margin: 15px"
                justify="center"
              >
                <a-col :span="12">
                  <a-button size="large" type="" @click="hide">取消</a-button>
                </a-col>
                <a-col :span="12">
                  <a-button size="large" type="primary" @click="push"
                    >推送</a-button
                  ></a-col
                >
              </a-row>
            </a-tab-pane>
            <a-tab-pane key="1" tab="传播激励" style="padding: 20px"
              ><h4>红包奖励对象</h4>

              <a-checkbox-group
                v-model="checkedList"
                :options="plainOptions"
                @change="onChange"
              />
            </a-tab-pane>
          </a-tabs>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
 
 <script>
import richeditor from "@/components/RichEditor.vue";
const plainOptions = ["合伙人", "客户"];
export default {
  name: "H5editor",
  data() {
    return {
      checkedList: ["合伙人"],
      plainOptions,
      indeterminate: true,
      // showEditor:false
    };
  },
  props: {
    // showEditor:false
    showEditor: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    richeditor,
  },
  methods: {
    onChange(checkedList) {
      this.indeterminate =
        !!checkedList.length && checkedList.length < plainOptions.length;
      this.checkAll = checkedList.length === plainOptions.length;
    },
    hide() {
      this.showEditor = false;
    },
  },
  watch: {},
  mounted: {},
};
</script>
 
 <style scoped>
 ::-webkit-scrollbar { width: 10px; cursor: default; }
::-webkit-scrollbar { width: 10px; height: 10px; overflow: auto; }
::-webkit-scrollbar-thumb { background-color: #d2d3d4; min-height: 25px; min-width: 25px; border: 1px solid #e0e0e0; border-radius: 20px; }
::-webkit-scrollbar-track { background-color: #f2f3f4; border: 1px solid #efefef; }
.iframe-p {
  background: url("../../../../public/Image/iphone.png") no-repeat center
    center/100% 100%;
  width: 375px;
  overflow: hidden;
  padding:30px 16px;
  height: 80vh;
  margin: 50px auto;
}
.line {
  border-top: 1px solid #e8e8e8;
}
::v-deep .ant-input {
  border: none;
  resize: none;
  box-shadow: none;
}
::v-deep .ant-input:focus {
  border: none;
}
.push-info {
  z-index: 199999;
  height: 100%;
}
.show {
  display: block;
}
.hidden {
  display: none;
}
.phone-pre {
  text-align: center;
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;

  background: rgba(255, 255, 255, 0.75);
  z-index: 99999;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
</style>