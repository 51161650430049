import { render, staticRenderFns } from "./H5editor.vue?vue&type=template&id=56c7beee&scoped=true&"
import script from "./H5editor.vue?vue&type=script&lang=js&"
export * from "./H5editor.vue?vue&type=script&lang=js&"
import style0 from "./H5editor.vue?vue&type=style&index=0&id=56c7beee&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.9.8@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56c7beee",
  null
  
)

export default component.exports